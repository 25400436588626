<template>
  <!-- NAME[epic=admin] 超管账户 -->
  <div class="orderTest-container">
    <el-button type="success" @click="handleCreate">创建</el-button>
    <el-button type="primary" @click="handleSet">设置职位权限</el-button>
    <el-table v-loading="loading" stripe :data="list" style="margin-top: 15px">
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        align="center"
      ></el-table-column>
      <el-table-column
        prop=""
        align="center"
        label="操作"
        fixed="right"
        min-width="160px"
      >
        <template #default="{ row }">
          <el-button type="text" @click.native.prevent="handleChcek(row)">
            查看
          </el-button>
          <el-button type="text" @click.native.prevent="handleEdit(row)">
            编辑
          </el-button>
          <el-button type="text" @click.native.prevent="handlePermission(row)">
            权限
          </el-button>
          <el-button
            v-if="row.status == 1"
            type="text"
            @click.native.prevent="handleStop(row)"
          >
            封存
          </el-button>
          <el-button
            v-if="row.status == 2"
            type="text"
            @click.native.prevent="handleEnable(row)"
          >
            启用
          </el-button>
          <el-button
            v-if="row.status == 2"
            type="text"
            @click.native.prevent="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <tpl ref="tpl" @refresh="fetchData"></tpl>
    <permission ref="permission" @refresh="fetchData"></permission>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { adminList, deleteAdmin, stopAdmin } from '@/api/admin/adminAccount'
  import tpl from './components/template'
  import Permission from './components/permission'
  export default {
    name: 'AdminAccount',
    components: { tpl, Permission },
    data() {
      return {
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          pageSize: 10,
          pageNo: 1,
        },
        loading: false,
        checkList: [
          '管理员ID',
          '姓名',
          '账号',
          '注册用户数',
          '正式用户数',
          '状态',
        ],
        columns: [
          {
            order: 1,
            label: '管理员ID',
            prop: 'id',
            width: '90',
          },
          {
            order: 2,
            label: '姓名',
            prop: 'truename',
            width: '160',
          },
          {
            order: 3,
            label: '账号',
            prop: 'username',
            width: '230',
          },
          {
            order: 4,
            label: '注册用户数',
            prop: 'dealer_type1_num',
            width: '90',
          },
          {
            order: 5,
            label: '正式用户数',
            prop: 'dealer_type2_num',
            width: '90',
          },
          {
            order: 6,
            label: '状态',
            prop: 'status_text',
            width: '100',
          },
        ],
        list: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {},
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await adminList(this.form)
        this.list = data
        this.total = totalCount
        this.loading = false
      },
      // 创建
      handleCreate() {
        this.$refs.tpl.title = '创建管理员账号'
        this.$refs.tpl.type = 3
        this.$refs.tpl.showDialog = true
      },
      // 设置职位权限
      handleSet() {},
      // table handle
      handleChcek(row) {
        this.$refs.tpl.title = '查看管理员账号'
        this.$refs.tpl.type = 1
        this.$refs.tpl.id = row.id
        this.$refs.tpl.showDialog = true
      },
      handleEdit(row) {
        this.$refs.tpl.title = '编辑管理员账号'
        this.$refs.tpl.type = 2
        this.$refs.tpl.id = row.id
        this.$refs.tpl.showDialog = true
      },
      handlePermission(row) {
        this.$refs.permission.form = row
        this.$refs.permission.showDialog = true
      },
      handleStop(row) {
        this.$confirm('封存此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            stopAdmin({ id: row.id }).then((res) => {
              this.fetchData()
              this.$message.success('封存成功')
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleEnable(row) {
        stopAdmin({ id: row.id }).then((res) => {
          this.fetchData()
          this.$message.success('启用成功')
        })
      },
      handleDelete(row) {
        this.$confirm('确定删除此项吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteAdmin({ id: row.id }).then((res) => {
              this.fetchData()
              this.$message.success('删除成功')
            })
          })
          .catch(() => {
            console.log('no')
          })
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
