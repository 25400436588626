var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "管理员权限",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "60px",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "truename" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.truename,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "truename", $$v)
                      },
                      expression: "form.truename",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话", prop: "mobile" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "username" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "职务", prop: "role_name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.role_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "role_name", $$v)
                      },
                      expression: "form.role_name",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { "margin-top": "15px" },
              attrs: { stripe: "", data: _vm.list, height: "450" },
            },
            _vm._l(_vm.finallyColumns, function (item, tableIndex) {
              return _c(
                "el-table-column",
                {
                  key: tableIndex,
                  attrs: {
                    prop: item.prop,
                    label: item.label,
                    width: "",
                    align: item.al,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (ref) {
                          var row = ref.row
                          return [
                            item.label == "操作"
                              ? _c(
                                  "div",
                                  [
                                    _c("el-checkbox", {
                                      model: {
                                        value: row.checked,
                                        callback: function ($$v) {
                                          _vm.$set(row, "checked", $$v)
                                        },
                                        expression: "row.checked",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : item.label == "权限"
                              ? _c(
                                  "div",
                                  _vm._l(row._sub_, function (item, index) {
                                    return _c(
                                      "span",
                                      {
                                        key: index,
                                        staticStyle: { "margin-right": "15px" },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            model: {
                                              value: item.checked,
                                              callback: function ($$v) {
                                                _vm.$set(item, "checked", $$v)
                                              },
                                              expression: "item.checked",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.title) + " "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              : _c("div", [
                                  _vm._v(" " + _vm._s(row[item.prop]) + " "),
                                ]),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  item.label == "权限"
                    ? _c("template", { slot: "header" }, [
                        _c("div", { staticStyle: { "text-align": "center" } }, [
                          _vm._v("权限"),
                        ]),
                      ])
                    : _vm._e(),
                ],
                2
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px", "text-align": "right" } },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("确定")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }