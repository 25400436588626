<template>
  <el-dialog
    :modal="false"
    title="管理员权限"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="60px"
        label-suffix=":"
      >
        <el-form-item label="姓名" prop="truename">
          <el-input
            v-model="form.truename"
            disabled
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input
            v-model="form.mobile"
            disabled
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="username">
          <el-input
            v-model="form.username"
            disabled
            style="width: 160px"
          ></el-input>
        </el-form-item>
        <el-form-item label="职务" prop="role_name">
          <el-input
            v-model="form.role_name"
            disabled
            style="width: 160px"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="loading"
        stripe
        :data="list"
        style="margin-top: 15px"
        height="450"
      >
        <!-- 选择框 -->
        <!-- <el-table-column align="center" type="selection" width="50">
          <template slot="header" slot-scope="scope">操作</template>
        </el-table-column> -->
        <el-table-column
          v-for="(item, tableIndex) in finallyColumns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          width=""
          :align="item.al"
        >
          <template #default="{ row }">
            <div v-if="item.label == '操作'">
              <el-checkbox v-model="row.checked"></el-checkbox>
            </div>
            <div v-else-if="item.label == '权限'">
              <span
                v-for="(item, index) in row._sub_"
                :key="index"
                style="margin-right: 15px"
              >
                <el-checkbox v-model="item.checked">
                  {{ item.title }}
                </el-checkbox>
              </span>
            </div>
            <div v-else>
              {{ row[item.prop] }}
            </div>
          </template>
          <template v-if="item.label == '权限'" slot="header">
            <div style="text-align: center">权限</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 15px; text-align: right">
      <el-button type="primary" @click="handleSubmit">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import { permissionDetail } from '@/api/admin/adminAccount'
  import { postAction } from '@/api/Employee'
  export default {
    name: '',
    components: {},
    data() {
      return {
        form: {
          truename: '',
          mobile: '',
          username: '',
          role_name: '',
        },
        loading: false,
        showDialog: false,
        list: [],
        checkList: ['操作', '功能', '权限'],
        columns: [
          {
            order: 1,
            label: '操作',
            prop: '',
            width: '70',
            al: 'center',
          },
          {
            order: 2,
            label: '功能',
            prop: 'title',
            width: '120',
            al: 'center',
          },
          {
            order: 3,
            label: '权限',
            prop: '',
            width: '',
            al: 'left',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await permissionDetail({ id: this.form.id })
        this.list = data
        this.loading = false
      },
      handleSubmit() {
        let d = []
        let endData = {
          id: this.form.id,
        }
        this.list.forEach((i) => {
          if (i.checked) {
            d.push(i.node)
          }
          if (i._sub_) {
            i._sub_.forEach((z) => {
              if (z.checked) {
                d.push(z.node)
              }
            })
          }
        })
        endData.auth_data = JSON.stringify(d)
        postAction('/superAdmin/authority/auth-set', endData).then((r) => {
          this.$message.success('修改成功')
          this.showDialog = false
          this.$emit('refresh')
        })
      },
      close() {
        this.showDialog = false
      },
    },
  }
</script>
<style lang="scss" scoped></style>
